.snackbar {
  position: fixed;
  width: 280px;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #F0FCF4;
  color: #2EB358;
  border: 1px solid #2EB358;
  padding: 10px 20px;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 9999;
}


.snackbar.visible {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.message {
  margin-right: 10px;
  font-weight: 400;
  font-size: 16px;
  font-family: Neue Haas Grotesk Text Pro;
}

.closeButton {
  width: 20px;
  background: none;
  border: none;
  color: #2EB358;
  font-size: 18px;
  cursor: pointer;
  margin-left: auto;
}
.error {
  position: fixed;
    width: 280px;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color:  indianred;
     color: #ffffff;
    padding: 10px 20px;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: none;
    z-index: 9999; 
    border: 1px solid indianred

}
.error .closeButton {
  color: rgb(181, 36, 36); 
}

.snackbar {

  &.error {
    background-color: #FFEBEE;
    color: #D32F2F;
  }

}
