.loader_wrapper {
    min-height: 590px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container {
    margin: 5px 10px;
    max-width: 15%;
    display: flex;
    flex-direction: row;
}

.page_title {
    /* padding-top: 12px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.property{
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

label {
    color: var(--neutral-colors-grey-900, #464D48);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    
    margin-top: 20px;
}

input {
    height: 19px;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--neutral-colors-grey-300, #E1E5E2);
    background: var(--interface-colors-white, #FFF);
    gap: 8px;
}

select, option {
    padding: 8px 8px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--neutral-colors-grey-300, #E1E5E2);
    background: var(--interface-colors-white, #FFF);
    gap: 8px;
}
.checkboxWrapper {
    display: flex;
    margin-bottom: 8px;
}

.checkboxWrapper input {
    margin-right: 8px;
}
.group_name{
    display: flex;
    margin-top: 2%;
}
.modalcontainer{
    padding: 40px;
}
.updateButtons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2%;

    
}