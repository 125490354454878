.page_title {
  /* padding-top: 12px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loader_wrapper {
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

._list {
  margin-top: 1em;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 2%;
  row-gap: 2em;
  margin-bottom: 100px;
}

.content {
  display: flex;
}

.success {
  margin: 0;
  background-color: #CAFCDA;
  border-radius: 5px;
  padding: 5px 10px;
}

.error {
  margin: 0;
  background-color: #FCF0F0;
  border-radius: 5px;
  padding: 5px 10px;
}

@media only screen and (max-width: 1000px) {
  .accounts_list {
    column-gap: 4%;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 720px) {
  .accounts_list {
    column-gap: 4%;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 500px) {
  .accounts_list {
    column-gap: 4%;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 300px) {
  .accounts_list {
    column-gap: 4%;
    grid-template-columns: repeat(1, 1fr);
  }
}