.loader_wrapper {
    min-height: 590px;
    width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    box-sizing: border-box;
    overflow-y: auto; 
    height: 600px;
}

label,p{
    color: black;
    font-size: 16px;
    
}

.subsBtn {
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
  margin-top: 80%;
  
}

.btn {
    margin-left: 10px;
}

.settings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
   
}

.accountSettings {
    display: flex;
    flex-direction: column;
    
}

.accountSettings .property {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 278px;
    align-items: flex-start;
    gap: 4px;
    
}
.accountSettings .property.row{
    flex-direction: row;
}

.button {
    font-size: 14px;
    width: max-content;
    height: max-content;
    float: right;
    white-space: nowrap;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid var(--neutral-colors-grey-300, #e1e5e2);
    padding: 11px 16px;
    font: 500 14px/14px Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
      margin-right: 12px;
      
     
  }
  
.property{
    display: flex;
    flex-direction: column;
    
}

.bts{
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 14px;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
      "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
      "Source Han Sans CN", sans-serif;
    color: rgb(255, 255, 255);
    font-size: 30px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    z-index: 70;
}
.accountSettings .property label {
    color: var(--neutral-colors-grey-900, #464D48);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    
    margin-top: 20px;
    
}
.txt {
    margin-left: 47px;
    width: 289px;
    color: var(--Neutral-Colors-Grey---900, #464D48);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 10%; 
}
.accountSettings {
    width: 336px;
    display: flex;
    flex-direction: column;
    margin: 5px;
}


.accountSettings .property input {
    height: 19px;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--neutral-colors-grey-300, #E1E5E2);
    background: var(--interface-colors-white, #FFF);
    gap: 8px;
}
.accountSettings .property .code{
    padding: 6px 12px;
    border: 1px solid var(--neutral-colors-grey-300, #E1E5E2);
    flex-grow: 1;
    border-radius: 4px;
    color: #464D48;
    min-height: 22px;
}

.subsSettings {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    margin-left: 13px;
    margin-bottom: 20px;
    
    
}

.subsProperty {
    display: flex;
    align-items: center;
}

.subsSettings input {
    padding: 5px;
    margin: 7px 7px 0 0;
    border-radius: 3px;
    border-width: thin;
    width: fit-content;
}


.clm{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.UpdateButtons{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 336px;
justify-content: space-between;
    
}

.integration {
    width: 300px;
    display: flex;
    flex-flow: row wrap;
    
  }
  .centeredWrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
  }
  
  .mainheading{
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
  }
  .IntegrationHeading{
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
    margin-top: 20px;
    margin-bottom: 10px;;
  }
  .UserDropdown{
    margin-top: 20px;
  }
  .tableHeader {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
  }
.licenseAccrdn{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    height: 160px;
}
  @media only screen and (max-width: 500px) {
    .settings {
        flex-direction: column;
        margin: 0;
        padding: 0;
        width: 450px;

    }
    .loader_wrapper {
        width: 450px;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .accountSettings .property {
        max-width: none;
    }
    
    .subsBtn {
        margin-left: auto; 
    }
}