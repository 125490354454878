.container{
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    margin-top:10px;
    margin-right:0px;

}
.filterstyles{
    display: flex;
width: 182px;
height: 33px;
padding: 8px 12px;
align-items: center;
gap: 8px;
border: none;
border-radius: 4px;
color: #B0B0B0;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 160%; 
width: 133px;
flex-shrink: 0;

}
.filterSelect{
    display: flex;
    color: var(--Neutral-Colors-Grey---900, #464D48);
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 160%; 
width: 180px;
height: 50px;
cursor: pointer;
padding: 8px 12px;
align-items: center;
gap: 8px;
border-radius: 4px;
border: 1px solid var(--Neutral-Colors-Grey---300, #E1E5E2);
background: var(--Interface-Colors-White, #FFF);
}
.FilterButton{
    display: flex;
height: 50px;
cursor: pointer;
justify-content: space-evenly;
align-items: center;
gap: 6px;
width: 60px;
color: #34CB65;
font-size: 16px;
font-style: normal;
font-weight: 500;
border: 1px solid var(--Neutral-Colors-Grey---300, #E1E5E2);
line-height: 16px;
border-radius: 4px;
background: var(--Interface-Colors-Green---300, #ffffff);
}
.exportButton{
display: flex;
align-items: flex-end;
cursor: pointer;
width: 142px;
height: 33px;
padding: 10px 24px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: 4px;
border: 1px solid var(--Neutral-Colors-Grey---300, #E1E5E2);
color: #34cb65;
width: 60px;
height: 50px;
font-size: 16px;
font-style: normal;
margin-top: 10px;
font-weight: 500;
line-height: 16px;
background: var(--Interface-Colors-Green---300, #ffffff);
}
.tools{
    display: flex;
    justify-content: flex-end;
}
.inputstyles{
    display:flex;
    border:1px solid var(--Neutral-Colors-Grey---300, #E1E5E2);
     border-radius:5px;
     height: 49px;
     
}

.inputKeys{
    display: flex;
    margin-right: 8px;
    margin-bottom: 8px
}