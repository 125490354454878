:root {
  /* Colors */
  --green_500: #28994b;
  --green_400: #2eb258;
  --green_300: #34cb65;
  --green_200: #cafcda;
  --green_100: #f0fcf4;

  
 
  
  

 
}

body {
  margin: 0;
  /* overflow-y: hidden; */
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  text-transform: none !important;
}

.MuiDataGrid-row {
  cursor: pointer;
}

.MuiDataGrid-cell {
  outline: none !important;
}

.MuiDataGrid-columnHeader {
  outline: none !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
}

.lastcolumnSeparator .MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}
@media (max-width: 767px) {
  body {
    zoom: 56%;
  }
}