.input,
.date_input {
  border-radius: 6px;
  padding: 10px 12px;
  font-size: 16px;
  border: 1px solid grey;
  width: 130px;
  margin-right: 35px;
}

.container {
  margin: 0 auto; 
  display: flex;
  justify-content: space-between; 
  align-items: flex-start;
}

.main {
  width: 100%;
  height: 500px;
  box-sizing: border-box;
align-items: center;  padding: 20px; 
}

.loader_wrapper {
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.default_para {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: #444;
}

.stats_container {
  text-align: center;
  margin-right: 50px;
}

.stats_heading {
  font-size: 14px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.6);
}

.stats_val {
  font-size: 20px;
  margin-top: 6px;
}
.loader_wrapper{
  margin-left: 50%;
}
.h2 {
  text-align: center;
  font-size: 26px;
  color: rgba(0, 0, 0, 0.6);
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
  .stats_heading {
    font-size: 16px;
  }

  .stats_val {
    font-size: 24px;
  }

  .input,
  .date_input {
    width: 100%;
    box-sizing: border-box;
  }
}
.dateInputs{
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 600px) {
  .form {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .input,
  .date_input {
    width: 100%;
    box-sizing: border-box;
  }
  .container {
    display: flex;
    align-items: flex-start;
    flex-direction:column;
    height: 450px;
  }
  .loader_wrapper{
    margin-left: 0%;
  }
}
