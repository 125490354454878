.loader_wrapper {
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  margin-top: 495px;
}

@media (max-width: 1000px) {
  .header {
      margin-top: 0px;
  }
}

.licenseTable {
  width: 492px;
  height: 340px;
  border-collapse: collapse;
}

.licenseTable th, .licenseTable td {
  border: none;
  padding: 8px;
  text-align: left;
  width: 82px;
  height: 52px;
}

.licenseTable th {
  background-color: #f2f2f2;
}
.vnForm{
  display: flex;
  flex-direction: column;
}
.MLicences{
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  width: 278px;
  margin-left: 71px;
  height: 80px;
}
.reportDownload{
  margin-top: 20px;
}

