.modal_background {
  background-color: rgba(102, 102, 102, 0.299);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
  transition: all 0.4s;
}

.modal_foreground {
  background-color: white;
border-radius: 10px;

left: 50px;
 top:-67px;

 
  border: 1px;
}

.modal_heading {
  padding: 15.9px, 15.9px, 15.9px, 23.84px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 9.94px, 9.94px, 0px, 0px;
  font-weight: 500;
  border: 0px, 0px, 0.75px, 0px;
  font-size: 16px;
  position: relative;
  height:58px;
  color: #464D48;
  background-color: #F0FCF4;
  border-bottom: 1px solid black;
background: #F7FAF8; border-top-left-radius: 10px; border-top-right-radius: 10px; border-bottom: 0.75px #E1E5E2 solid; 

}


.close_btn {
  border: none;
  cursor: pointer;
  color: grey;
  width: Fixed (24px);
  height: Fixed (24px);
  justify-content: space-between;
  padding-right: 20px;
  
}

.modal_body {
  /* min-height: 20vh;
  max-height: 75vh;
   overflow: scroll; 
   padding: 0 1em; 
  padding: 1em;  */
}


h3{
height: 24px;
font-size: 16px;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
padding-left: 20px;
font-family: sans-serif inter;
}
