.action_btn {
  float: right;
}
.modal {
  padding-bottom: 4em;
}
.modal p {
  font-weight: 400;
  font-size: 18px;
}
