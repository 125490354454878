@import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

.login-page-container {
  display: flex;
  
}

.login-container {
  justify-content: center;
  align-items: center;
  background: #ffffff;

  transition: 0.3s;
  border-radius: 8px;
  margin: 20vh auto;

}

.login-header {
  margin-bottom: 20px;
  text-align: center;
  font: 500 1.25em "Poppins";
  padding-bottom: 12px;
}

.form {
  padding: 20px 40px 20px 40px;
  text-align: center;
  
}

.form input {
  margin-bottom: 30px;
  padding-bottom: 12px;
 
}

.form span {
  margin-bottom: 30px;
}

.form input {
  width: 100%;
  box-sizing: border-box;
  font-size: 1em;;;
  height: 40px;
  color: #2c2b2b;
  border: 1px solid var(--Neutral-Colors-Grey---300, #E1E5E2);
  border-radius: 4px;
  background: var(--Interface-Colors-Green---300, #ffffff);
    
}

.form button {
  width: 100%;
  background-color: #34cb65;
  text-transform: none;
  
}

.form button:hover {
  background-color: #34cb65;
}

.form button:active {
  background-color: #34cb65;
}

.form label {
  margin: auto;
  float: left;
  font: 500 0.8em "Poppins";
  
}
.headings{
  float: left;
  width: 1px;
}