.page_container {
   padding-left: 5.5%;
   min-width: 500px;
   padding-right:2%;
   transition: 0.3s;
 }

 .loader_wrapper {
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
 
 .breadcrumbs {
   padding-top: 10px;
 }
 
 .breadcrumbs li {
   font-size: 1.2em; 
   font-weight: 600;
 }
 
 .breadcrumbs a {
   color: #FF5733; 
   text-decoration: none;
 }
 
 .breadcrumbs a:hover {
   text-decoration: none;
   font-weight: bolder; 
 }
 
 .inovua-react-scroll-container__scroller {
   margin-bottom: 10px !important;
 }
 @media screen and (max-width: 1000px) {
  .page_container {
    padding-left: 10%;
  }
}

@media screen and (max-width: 500px) {
  .page_container {
    padding-left: 13%;
   padding-right: 6%;
  }
}
