.container {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-top: 55px;
}
.loader_wrapper {
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.label{
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
}

.subsBtn {
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    position: absolute;
    bottom: 4%;
    margin-right: 200px;
}

.btn {
    margin-left: 10px;
}

.settings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   
}



.updateButtons {
    display: flex;
    flex-direction: row;
    margin-top: auto;
}

.accountSettings .property {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 278px;
    align-items: flex-start;
    gap: 4px;
    
}
.button {
    font-size: 14px;
    width: max-content;
    height: max-content;
    float: right;
    white-space: nowrap;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid var(--neutral-colors-grey-300, #e1e5e2);
    padding: 11px 16px;
    font: 500 14px/14px Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
      margin-right: 12px;
      
     
  }
  
.property{
    display: flex;
    flex-direction: column;
    position: relative;
}
.txt {
    margin-left: 47px;
    width: 289px;

}
.accountSettings {
    display: flex;
    flex-direction: column;
}

.accountSettings .property label {
    color: var(--neutral-colors-grey-900, #464D48);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-top: 20px;
    
}

.accountSettings .property input {
    height: 19px;
    
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--neutral-colors-grey-300, #E1E5E2);
    background: var(--interface-colors-white, #FFF);
    gap: 8px;
}

.subsSettings {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    margin-left: 13px;
    margin-bottom: 20px;
    
}

.subsProperty {
    display: flex;
    align-items: center;
}

.subsSettings input {
    padding: 5px;
    margin: 7px 7px 0 0;
    border-radius: 3px;
    border-width: thin;
    width: fit-content;
}

@media only screen and (max-width: 1000px) {
    .header {
        display: flex;
        position: absolute;
        bottom: 4%;
        right: 0px;
        margin-right: 100px;
        padding-top: 30px;
    }
    .accountSettings {
        width: 336px;
        display: flex;
        flex-direction: column;
    }
    
    .settings {
        flex-direction: column;
        margin: 0;
        padding: 0;
    }

    .accountSettings .property {
        max-width: none;
    }
    
    .subsBtn {
        margin-left: auto; 
    }
}
.clm{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.discountKey{
    display: flex;
  width: 142px;
  height: 46px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--neutral-colors-grey-300, #E1E5E2);
  background: var(--interface-colors-white, #FFF);
  }
.mainheading{
    width: 278px;
    height: 25px;
    top:86px;
    left: 30px;
    color: #000;
    font-size: 16px;
    font-weight: 500px;
    line-height: 20px;
  }
  .startTrial{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
  }
  .startTrialtxt{
 font-size: 18px;
   display: flex;
  justify-content: space-around;
  margin: 37px;
  }
  .discountContainer {
  justify-content: space-between;
  width: 300px; 
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.labell {
  margin-right: 10px;
  border: 1px solid black;
  width: 100px;
  height: 50px;
  display: flex;
  width: 142px;
  height: 46px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--neutral-colors-grey-300, #E1E5E2);
  background: var(--interface-colors-white, #FFF);
}

