.vnForm {
    position: relative;
    padding-bottom: 4em;
    padding: 24px;
    padding-left: 31px;
    height: 300x;
    padding-right: 31px;
}
  
.vnForm label {
    display: flex;
    flex-direction: column;  
    margin-bottom: 10px;
}

.vnForm input {
    outline: none;
    font-size: 1em;
    padding: 4px 8px;
    border-radius: 6px;
    width: 278px;
}

.action_btn {
    float: right;
}
