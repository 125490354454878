.modalBody {
  min-height: max-content;
  width: 350px;
}

.modalBody form {
  display: flex;
  flex-direction: column;
  padding: 24px 31px;
  height: auto;
}

.modalBody input {
  outline: none;
  font-size: 14px;
  padding: 8px;
  width: 100%; /* Ensures consistent width */
  box-sizing: border-box; /* Avoids overflowing content */
}

.modalBody .MuiOutlinedInput-root {
  height: 40px;
  border-radius: 4px;
}

.modalBody .MuiInputBase-input {
  padding: 8px;
  font-size: 14px;
}

.uploadFileLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
  padding: 20px;
  border: 2px dashed #dedede;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.uploadFileLabel:hover {
  background-color: #f9f9f9;
}

.uploadFileLabel input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.uploadFileLabel .noFile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: #919994;
}

.uploadFileLabel .noFile a {
  color: #34cb65;
  font-weight: 500;
  text-decoration: none;
}

.uploadFileLabel .noFile a:hover {
  text-decoration: underline;
}

.uploadFileLabel .fileSelected {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.fileSelected p {
  font-weight: bold;
  margin: 5px 0;
}

.fileSelected button {
  font-size: 12px;
  color: red;
}
