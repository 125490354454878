/* Styles for the brand/logo section */
.brand {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
 
}
ul{
  padding: 0%;
}
.brand img {
  width: 48px;
  height: 24px;
  margin-left: 8px;
  margin-right: 10px;
}





.side_nav_container {
  transition: 0.1s;
  height: 100%;
  width: var(--sidebar-width);
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #414c58;
  top: 0;
  z-index: 2;
  overflow-x: hidden;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5); 
}

ul {
  list-style-type: none;
}

/* Styles for the navigation menu */
.nav_menu {
  list-style: none;
  padding: 0;
  border: none;
  
}

.nav_menu .menu_item {
  font-family: Arial, Helvetica, sans-serif;
  
  list-style-type: none;
  font-size: large;
  cursor: pointer;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;

 border: none;
}


.nav_menu a {
  text-decoration: none;
  color: #414c58;
}


.active {
  transition: border 0.2s ;
  border-right: 4px solid black;
  transition: 0.5s;
  height: auto;
  background-color: white;
  box-shadow: 0 0 1px rgba(63, 63, 63, 0.5);
}

.title_line {
  
  border-top: 2px solid;  
  margin-top: 30px;  
  color:  rgba(76, 75, 75, 0.5);
  padding: 10px;
   font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  margin-left: 20px;
  width: 150px;
}


.menu_item.active {
  border-right: 3px solid #2EB258;
  
}
.menu_item:hover {
  border-right: 3px solid #2EB258; 
  background-color: #ffffff;
  transition: border 0.2s ;

}

.menu_item {
  display: flex;
  align-items: center;
  padding: 15px;
  text-decoration: none;
  color: white;
}

.menu_item.active {
  background-color: #F0FCF4; 
  color: #2EB258; 
}
.logout2{
  position: fixed;
  bottom: 15px;
  cursor: pointer;
  color: indianred;
  left: 18px;
}

@media (max-width: 768px) {
  .side_nav_container {
    width: 100%;
    position: fixed;
    top: 0;
    transition: left 0.3s ease-in-out;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }

}
