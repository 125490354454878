
.accounts_list {
    height: 75vh;
    
}

.loader_wrapper {
    width: 100%;
    
    
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.centeredWrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
  }
